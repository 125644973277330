<template>
    <div>
        <div class="ui very relaxed stackable prescription grid">
            <div class="column">
                <div class="ui simple middle aligned heading grid">
                    <div class="mobile only two column row">
                        <div class="column">
                            <router-link class="back link" :to="backRoute? backRoute : { name: 'OutstandingRecords' }">
                                <i class="fontello-left"></i>
                            </router-link>
                        </div>
                        <div class="right aligned column">
                            <button type="button" class="ui circular icon alt teal small button" @click="editPrescription"><i class="pencil icon"></i></button>
                        </div>
                    </div>
                    <div class="mobile only row">
                        <div class="column"><div class="ui hidden medium divider"></div></div>
                    </div>
                    <div class="row">
                        <div class="sixteen wide mobile eight wide tablet eight wide computer column">
                            <h3 class="ui header">
                                Treatment Prescription
                            </h3>
                        </div>
                        <div class="computer only tablet only eight wide tablet eight wide computer right aligned column">
                            <button type="button" class="ui circular icon alt teal small button" @click="editPrescription"><i class="pencil icon"></i></button>
                            <router-link class="ui small alt button" :to="backRoute? backRoute : { name: 'OutstandingRecords' }">
                                <i class="fontello-left"></i> Back
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="ui hidden divider"></div>

                <transition name="vue-fade">
                    <div v-if="loading">
                        <div class="ui placeholder">
                            <div class="header">
                                <div class="very short line"></div>
                                <div class="medium line"></div>
                            </div>
                            <div class="paragraph">
                                <div class="short line"></div>
                            </div>
                        </div>
                    </div>
                    <template v-else>
                        <prescription-detail :prescription="prescription" :customer="customer" @show:history="showPrescriptionHistory" relaxed="very relaxed" timestamped v-if="prescription"/>
                    </template>
                </transition>
            </div>
            <div class="five wide column">
                <div v-if="loading">
                    <div class="ui placeholder">
                        <div class="header">
                            <div class="very short line"></div>
                            <div class="medium line"></div>
                        </div>
                        <div class="paragraph">
                            <div class="short line"></div>
                        </div>
                    </div>
                </div>
                <template v-else>
                    <template v-if="aftersales">
                        <button type="button" class="ui circular icon alt teal small right floated button" @click="editAfterSales"><i class="pencil icon"></i></button>
                        <after-sales :aftersales="aftersales" :customer="customer" @show:history="showAfterSalesHistory" timestamped style="margin-top: 0.75em;"/>
                    </template>

                    <div class="ui info message" v-else>
                        <p>No after sales record. Create one here</p>
                        <div class="ui hidden medium divider"></div>
                        <div><after-sales-form :id="`aftersalessideform${_uid}`" class="side" :formdata="aftersalesformdata" @change:saving="afterSalesSavingListener" @save:aftersales="afterSalesSaved"/></div>
                        <div class="ui hidden divider"></div>
                        <p><button class="ui teal button" type="submit" :form="`aftersalessideform${_uid}`" :class="{loading: saving_aftersales}" :disabled="saving_aftersales">Submit</button></p>
                    </div>
                </template>

                <div class="ui hidden divider"></div>
            </div>
        </div>

        <div ref="prescriptionmodal" class="ui hidden prescription modal">
            <div class="content">
                <prescription-form ref="prescriptionform" :id="`prescriptionform${_uid}`" :prescription="prescriptionformdata" @change:saving="prescriptionSavingListener" @save:prescription="prescriptionSaved"/>
            </div>
            <div class="actions">
                <button type="submit" :form="`prescriptionform${_uid}`" class="ui submit teal button" :class="{loading: saving_prescription}" :disabled="saving_prescription">Update</button>
                <button type="button" class="ui alt red cancel button">Cancel</button>
            </div>
        </div>

        <div ref="aftersalesmodal" class="ui hidden modal">
            <div class="content">
                <h3 class="ui header">{{aftersales? "Update After Sales Record" : "Create After Sales Record"}}</h3>
                <div class="ui hidden divider"></div>
                <after-sales-form :id="`aftersalesmodalform${_uid}`" :formdata="aftersalesformdata" @change:saving="afterSalesSavingListener" @save:aftersales="afterSalesSaved"/>
            </div>
            <div class="actions">
                <button type="submit" :form="`aftersalesmodalform${_uid}`" class="ui submit teal button" :class="{loading: saving_aftersales}" :disabled="saving_aftersales">{{aftersales? "Update" : "Submit"}}</button>
                <button type="button" class="ui alt red cancel button">Cancel</button>
            </div>
        </div>

        <template v-if="isAdmin">
            <div ref="prescriptionhistorymodal" class="ui hidden large basic modal">
                <div class="content">
                    <history :key="`prescriptionhistory_${prescriptionhistory_key}`" :url="`prescriptions/${prescription.id}/history`" title="Treatment Prescription Revision" v-if="prescription">
                        <template #detail="{ history }">
                            <prescription-detail :prescription="history" relaxed="relaxed" readonly/>
                        </template>
                    </history>
                </div>
            </div>

            <div ref="aftersaleshistorymodal" class="ui hidden large basic modal">
                <div class="content">
                    <history :key="`aftersaleshistory_${aftersaleshistory_key}`" :url="`aftersales/${aftersales.id}/history`" title="After Sales Revision" v-if="aftersales">
                        <template #detail="{ history }">
                            <after-sales :aftersales="history" readonly/>
                        </template>
                    </history>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import clonedeep from "lodash.clonedeep";

import History from "@/components/History";

import PrescriptionDetail from "@/views/consultation/Prescription";
import AfterSales from "@/views/consultation/AfterSales";
import PrescriptionForm from "@/views/consultation/PrescriptionForm";
import AfterSalesForm from "@/views/consultation/AfterSalesForm";

export default {
    name: "prescription",
    components: {
        History,
        PrescriptionDetail,
        AfterSales,
        PrescriptionForm,
        AfterSalesForm
    },
    data() {
        const id = this.$route.params.id;

        return {
            id,
            customer: null,
            prescription: null,
            prescriptionformdata: {},
            aftersales: null,
            aftersalesformdata: {
                id
            },
            loading: true,
            saving_prescription: false,
            saving_aftersales: false,
            prescriptionhistory_key: (new Date()).getTime(),
            aftersaleshistory_key: (new Date()).getTime(),
            opened_modals: [],
            history_popped: false
        };
    },
    created() {
        const getPrescription = this.$http.get(`prescriptions/${this.id}`).then((response) => {
            this.prescription = response.data;

            this.$http.get(`customers/${this.prescription.customer_id}`).then(({ data }) => {
                this.customer = data;
            }).catch((error) => {
                console.error(error);
                this.$toasted.error("Unable to load customer.", {
                    duration: 3000
                });
            });
        }).catch((error) => {
            console.error(error);
            this.$toasted.error("Unable to load prescription.", {
                duration: 3000
            });
        });

        const getAfterSales = this.$http.get(`aftersales/${this.id}`).then((response) => {
            this.aftersales = response.data;
        }).catch((error) => {
            if(404 !== error.response?.status) {
                console.error(error);

                this.$toasted.error(this.after_sales_error, {
                    duration: 3000
                });
            }
        });

        Promise.all([getPrescription, getAfterSales]).then((_) => {
            this.loading = false;
        });
    },
    mounted() {
        window.addEventListener("popstate", this.popstateHandler);

        $(this.$refs.prescriptionmodal).modal({
            closable: false,
            autofocus: false,
            onVisible: () => {
                history.pushState(null, "prescriptionmodal_opened");
                this.opened_modals.push("prescriptionmodal");
            },
            onHide: () => {
                //maybe use onHidden?
                if(!this.history_popped) {
                    history.back();
                }

                this.opened_modals = this.opened_modals.filter((modal) => {
                    return modal !== "prescriptionmodal";
                });
            }
        });

        $(this.$refs.aftersalesmodal).modal({
            closable: false,
            onVisible: () => {
                history.pushState(null, "aftersalesmodal_opened");
                this.opened_modals.push("aftersalesmodal");
            },
            onHide: () => {
                //maybe use onHidden?
                if(!this.history_popped) {
                    history.back();
                }

                this.opened_modals = this.opened_modals.filter((modal) => {
                    return modal !== "aftersalesmodal";
                });
            }
        });

        if(this.isAdmin) {
            $(this.$refs.prescriptionhistorymodal).modal({
                centered: false,
                onVisible: () => {
                    history.pushState(null, "prescriptionhistorymodal_opened");
                    this.opened_modals.push("prescriptionhistorymodal");
                },
                onHide: () => {
                    //maybe use onHidden?
                    if(!this.history_popped) {
                        history.back();
                    }

                    this.opened_modals = this.opened_modals.filter((modal) => {
                        return modal !== "prescriptionhistorymodal";
                    });
                },
                onHidden: () => {
                    this.prescriptionhistory_key = (new Date()).getTime();
                }
            });

            $(this.$refs.aftersaleshistorymodal).modal({
                centered: false,
                onVisible: () => {
                    history.pushState(null, "aftersaleshistorymodal_opened");
                    this.opened_modals.push("aftersaleshistorymodal");
                },
                onHide: () => {
                    //maybe use onHidden?
                    if(!this.history_popped) {
                        history.back();
                    }

                    this.opened_modals = this.opened_modals.filter((modal) => {
                        return modal !== "aftersaleshistorymodal";
                    });
                },
                onHidden: () => {
                    this.aftersaleshistory_key = (new Date()).getTime();
                }
            });
        }
    },
    beforeDestroy() {
        window.removeEventListener("popstate", this.popstateHandler);
    },
    methods: {
        popstateHandler(event) {
            this.history_popped = true;
            this.opened_modals.forEach((modal) => {
                $(this.$refs[modal]).modal("hide");
            });

            this.history_popped = false;
        },
        editPrescription() {
            this.prescriptionformdata = clonedeep(this.prescription);

            $(this.$refs.prescriptionmodal).modal("show");
        },
        prescriptionSavingListener(saving) {
            this.saving_prescription = saving;
        },
        prescriptionSaved(prescription) {
            if(this.prescription) {
                this.prescription = prescription;
            }

            $(this.$refs.prescriptionmodal).modal("hide");

            this.prescriptionhistory_key = (new Date()).getTime();
        },
        editAfterSales() {
            if(this.aftersales) {
                this.aftersalesformdata = clonedeep(this.aftersales);
            } else {
                this.aftersalesformdata = {
                    id: this.prescription.id
                };
            }

            $(this.$refs.aftersalesmodal).modal("show");
        },
        afterSalesSavingListener(saving) {
            this.saving_aftersales = saving;
        },
        afterSalesSaved(aftersales) {
            this.aftersales = aftersales;
            $(this.$refs.aftersalesmodal).modal("hide");

            this.aftersaleshistory_key = (new Date()).getTime();
        },
        showPrescriptionHistory() {
            if(this.isAdmin) {
                $(this.$refs.prescriptionhistorymodal).modal("show");
            }
        },
        showAfterSalesHistory() {
            if(this.isAdmin) {
                $(this.$refs.aftersaleshistorymodal).modal("show");
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.back.link {
    display: inline-block;
    font-size: 1.35rem;
}

.ui.prescription.grid {
    > .column {
        &:last-child {
            background: #fff;
        }
    }

    @media only screen and (min-width: 768px) {
        margin: -2rem;
        min-height: calc(100vh - 56px);

        > .column:not(.row) {
            padding: 2rem;
        }
    }

    @media only screen and (min-width: 1201px) {
        > .column {
            &:first-child {
                flex: 1;
                min-width: 0;
            }

            &:last-child {
                background: #fff;
                min-width: 360px;
                max-width: 360px;
            }
        }
    }

    @media only screen and (max-width: 1200px) {
        > .column {
            width: 100% !important;
        }
    }
}
</style>